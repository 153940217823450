<template>
  <div class="page-wrap">
    <Breadcrumb class="my-bread">
      <BreadcrumbItem to="/zhiku"
        ><i class="iconfont iconxinxiku" style="margin-right: 10px;"></i>智库首页</BreadcrumbItem
      >
      <BreadcrumbItem
        v-if="$route.query.biomarkers"
        :to="`/zhiku/lists?id=${$route.query.biomarkers}&name=${$route.query.biomarkersName}`"
        >生物标志物</BreadcrumbItem
      >
      <BreadcrumbItem>详情</BreadcrumbItem>
    </Breadcrumb>
    <div class="header-wrap">
      <div class="title">
        {{ data.title }}
      </div>
      <FieldItem
        label="概述："
        :labelStyle="{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '16px' }"
        marginBottom="24px"
      >
        <div style="font-size: 16px;">
          {{ data.abstract }}
        </div>
      </FieldItem>
      <Row style="padding-left: 16px;">
        <Col span="12">
          <FieldItem label="发表时间：">{{ data.publish_year }}</FieldItem>
          <FieldItem v-if="data.journal" label="期刊名称：">{{ data.journal }}</FieldItem>
          <FieldItem label="影响因子：">{{ data.impact_factor }}</FieldItem>
          <FieldItem v-if="data.doi" label="DOI：">{{ data.doi }}</FieldItem>
          <FieldItem v-if="authors" label="通讯作者：">{{ authors }}</FieldItem>
          <FieldItem v-if="positions" label="通讯作者单位：">{{ positions }}</FieldItem>
          <FieldItem v-if="data.continent" label="地域信息：">
            {{ regionValue }}
          </FieldItem>
        </Col>
        <Col span="12">
          <FieldItem label="原文链接："><a :href="data.source_url" target="_blank">{{ data.source_url }}</a></FieldItem>
          <FieldItem v-if="treatments" label="治疗：" marginBottom="0">{{ treatments }}</FieldItem>
          <FieldItem v-if="populations" label="研究人群：">{{ populations }}</FieldItem>
          <FieldItem v-if="diseases" label="相关疾病：">{{ diseases }}</FieldItem>
          <FieldItem v-if="medicals" label="医学方向：">{{ medicals }}</FieldItem>
          <FieldItem v-if="biomarker" label="相关生物标志物：">{{ biomarker }}</FieldItem>
          <FieldItem v-if="instrument" label="试剂品牌：">{{ instrument }}</FieldItem>
          <FieldItem v-if="reagent" label="仪器厂家：">{{ reagent }}</FieldItem>
        </Col>
      </Row>
    </div>
    <div class="detail-content">
      <DetailItem title="推荐意见">
        <div class="main-point" v-for="(item, index) in recommendations" :key="index">
          <div class="title-wrap">
            <div>推荐意见{{ index + 1 }}：</div>
            <div class="tag">{{ item.data_type }}</div>
          </div>
          <div class="value">
            {{ item.content }}
          </div>
        </div>
      </DetailItem>
      <DetailItem title="要点">
        <div class="main-point" v-for="(item, index) in keyPoints" :key="index">
          <div class="title-wrap">
            <div>要点{{ index + 1 }}：</div>
            <div class="tag">{{ item.data_type }}</div>
          </div>
          <div class="value">
            {{ item.content }}
          </div>
        </div>
      </DetailItem>
      <DetailItem title="图片" v-if="data.images && data.images.length > 0">
        <div style="padding: 16px;display: flex;flex-wrap: wrap;">
          <div class="img-wrap" v-for="(item, index) in data.images" :key="index" @click="handlePreviewImg(item)">
            <img :src="item.url" alt="" />
            <div class="img-title">图：{{ item.name }}</div>
            <div v-if="item.comment" class="img-des">注释：{{ item.comment }}</div>
          </div>
        </div>
      </DetailItem>
      <DetailItem title="相关文献">
        <div style="padding: 16px">
          <div v-for="(item, index) in data.related_articles" :key="index">
            <p style="color: #007FFF;cursor: pointer" @click="newRelated(item)">{{ item.title }}</p>
          </div>
        </div>
      </DetailItem>
    </div>
  </div>
</template>

<script>
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'view-design'
import FieldItem from '@/components/Field/index.vue'
import DetailItem from '@/components/DetailItem/index.vue'
import { getZhiKuLiteratureDetail } from '@/api/zhiku'
import Zh_CnConuntry from '@/views/system/zhiku/conuntry.json'

export default {
  components: {
    Breadcrumb,
    BreadcrumbItem,
    Row,
    Col,
    FieldItem,
    DetailItem
  },
  data() {
    return {
      data: {},
      authors: '',
      positions: '',
      populations: '',
      biomarker: '',
      instrument: '',
      reagent: '',
      diseases: '',
      treatments: '',
      medicals: '',
      keyPoints: [],
      recommendations: []
    }
  },
  computed: {
    regionValue() {
      const country = this.data.country
        ? `/${this.data.country
            .split(',')
            .map(item => Zh_CnConuntry[item])
            .join(',')}`
        : ''
      const province = this.data.province && this.data.province !== 'null' ? `/${this.data.province}` : ''
      return `${this.data.continent ? this.data.continent : ''}${country}${province}`
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // 删除数组中的空元素，包括空字符、null、undefined
    filterEmpty(arr) {
      return Array.from(
        new Set(
          arr.filter(item => {
            return item
          })
        )
      )
    },
    fetchData() {
      getZhiKuLiteratureDetail(this.$route.query.id).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.data = res.data
          this.authors = this.filterEmpty(res.data.authors.map(item => item.name)).join(',')
          this.positions = this.filterEmpty(res.data.authors.map(item => item.institution)).join(',')
          this.populations = this.filterEmpty(res.data.populations.map(item => item.name)).join(',')
          this.biomarker = this.filterEmpty(
            res.data.biomarker_tags.map(item => item.biomarker && item.biomarker.name)
          ).join(',')
          this.instrument = this.filterEmpty(
            res.data.biomarker_tags.map(item => item.instrument && item.instrument.name)
          ).join(',')
          this.reagent = this.filterEmpty(res.data.biomarker_tags.map(item => item.reagent && item.reagent.name)).join(
            ','
          )
          this.diseases = this.filterEmpty(res.data.diseases.map(item => item.name)).join(',')
          this.treatments = this.filterEmpty(res.data.treatments.map(item => item.name)).join(',')
          this.medicals = this.filterEmpty(res.data.medicals.map(item => item.name)).join(',')
          this.keyPoints = res.data.key_points
          this.recommendations = res.data.recommendations
        }
      })
    },
    newRelated(item) {
      if (item.article_data_type === 'guide') {
        window.open(window.location.origin + '/#/zhiku/guide?id=' + item.id)
      } else {
        window.open(window.location.origin + '/#/zhiku/literature?id=' + item.id)
      }
    },
    handlePreviewImg(item) {
      if (item.url) {
        this.$viewerApi({
          images: [item.url]
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.my-bread {
  font-size: 16px;
  padding-top: 20px;
}

.header-wrap {
  margin: 20px 0 50px 0;
  padding: 40px;
  // height: 491px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(210, 210, 210, 0.49);
  border-radius: 12px;

  .title {
    font-size: 20px;
    font-weight: bold;
    color: #202327;
    line-height: 25px;
    margin-bottom: 24px;
  }
}

.img-wrap {
  width: 33%;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;

  .img-title {
    margin-top: 8px;
    font-weight: 500;
    padding: 0 16px;
  }

  .img-des {
    margin-top: 8px;
    font-size: 12px;
    color: #999999;
    padding: 0 16px;
  }

  & > img {
    width: 100%;
    height: 160px;
    object-fit: contain;
  }
}
.detail-content {
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(210, 210, 210, 0.49);
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 40px;

  .main-point {
    margin-top: 24px;

    .title-wrap {
      padding-left: 16px;
      font-size: 16px;
      font-weight: bold;
      color: #404e69;
      display: flex;
      align-items: center;
      line-height: 1;

      .tag {
        padding: 0 9px;
        height: 20px;
        box-sizing: border-box;
        margin-left: 12px;
        border-radius: 8px 8px 8px 0;
        background: linear-gradient(135deg, #ff9b44 0%, #ff5824 100%);
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
      }
    }
    .value {
      padding-left: 16px;
      margin-top: 16px;
    }
  }
}
</style>
